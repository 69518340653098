import React from 'react'
import { Link } from 'gatsby-plugin-modal-routing'
import { lock } from 'tua-body-scroll-lock'

import Img from 'gatsby-image'
import { connect } from 'react-redux'
import {
	divBoundingClientRect,
	imageBoundingClientRect,
	isAnimation,
	animationStatus,
} from '../actions/animationAction'

import { alignElements } from '../actions/alignAction'

class Project extends React.Component {
	onClick = e => {
		const animating = true
		const animeStatus = {
			play: true,
			reverse: false,
		}

		const targetElement = document.body
		lock(targetElement)
		document
			.getElementsByClassName('site-footer')[0]
			.classList.add('padding-right')
		//padding-right: 15px;
		const currentYOffset = window.pageYOffset
		global.offset = currentYOffset

		this.props.alignElements(this.props.index)

		//console.log(this.props.index)

		//global.offset = currentYOffset
		this.props.isAnimation(animating)
		this.props.animationStatus(animeStatus)
		//document.body.classList.add('no-scroll')
		const divRect = e.target.getBoundingClientRect()

		this.props.divBoundingClientRect(divRect)

		//////////
		const imageRectWidth = event.target.querySelector('.project-image')
			.offsetWidth
		const imageRectHeight = event.target.querySelector('.project-image')
			.offsetHeight
		const imageRect = event.target
			.querySelector('.project-image')
			.getBoundingClientRect()
		const logoRectHeight = event.target.querySelector('.project-logo-wrapper')
			.offsetHeight
		global.imageRectTop =
			event.target.querySelector('.project-image').offsetTop - logoRectHeight

		const logoH = event.target.querySelector('.project-title').offsetHeight
		const elH = event.target.getBoundingClientRect().height - logoH
		global.logoRectBottom =
			event.target.querySelector('.project-title').offsetTop - elH

		///////

		// const screenWidth =
		// 	window.innerWidth ||
		// 	document.documentElement.clientWidth ||
		// 	document.body.clientWidth

		// const container = document
		// 	.getElementsByClassName('projects-list')[0]
		// 	.getBoundingClientRect().width
		// const padding = 120 * 2
		// const colPadding = 12 * 2
		// const imageLeft = event.target
		// 	.querySelector('.project-image')
		// 	.getBoundingClientRect().left

		// global.imageRectLeft = (container - colPadding - imageLeft) / 2

		// console.log(event.target.getBoundingClientRect())

		// console.log(imageRectLeft)

		// const bla = event.target.querySelector('.project-image')
		// console.log(
		// 	window.innerWidth - (bla.getBoundingClientRect().left + bla.offsetWidth)
		// )

		global.logoRectTop = event.target.querySelector(
			'.project-logo-wrapper'
		).offsetTop
		global.logoRectLeft = event.target.querySelector(
			'.project-logo-wrapper'
		).offsetLeft

		const imageRectFull = {
			bottom: imageRect.bottom,
			height: imageRectHeight,
			left: imageRect.left,
			right: imageRect.right,
			top: imageRect.top,
			width: imageRectWidth,
			x: imageRect.x,
			y: imageRect.y,
		}
		this.props.imageBoundingClientRect(imageRectFull)
	}

	render() {
		const { data } = this.props
		const condition = this.props.data.acf.mobile_or_desktop.toLowerCase()
		return (
			<article key={data.wordpress_id} className="col-11 col-lg-5 project">
				<Link
					className={'js-project-link ' + condition}
					data-id={data.wordpress_id}
					data-modal={data.slug}
					to={'/projects/' + data.slug}
					//state={{ align: this.props.index }}
					onClick={this.onClick}
					asModal
				>
					<section className="project-intro">
						<div className="intro-wrapper">
							<div className="intro-images">
								{data.acf.project_logo_image !== null && (
									<div className="project-logo-wrapper">
										<div className="project-logo">
											<Img
												fluid={
													data.acf.project_logo_image.localFile.childImageSharp
														.fluid
												}
												alt={data.acf.project_logo_image.alt_text}
											/>
										</div>
									</div>
								)}
								{data.featured_media !== null && (
									<Img
										fixed={data.featured_media.localFile.childImageSharp.fixed}
										alt={data.featured_media.alt_text}
										className="project-image"
									/>
								)}
							</div>
						</div>
					</section>
					<div className="project-title">
						<h2 className="reveal-text">{data.title}</h2>
						<div className="title-wrapper">
							<h2>{data.title}</h2>
						</div>
					</div>
					<div className="overlay" />
				</Link>
			</article>
		)
	}
}

const mapStateToProps = state => ({
	divRect: state.divRect,
})

export default connect(
	mapStateToProps,
	{
		divBoundingClientRect,
		imageBoundingClientRect,
		isAnimation,
		animationStatus,
		alignElements,
	}
)(Project)
