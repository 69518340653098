import React, { Component } from 'react'
import { Link } from 'gatsby-plugin-modal-routing'

import Logo from '../images/logo.svg'

class Navbar extends Component {
	onNavOpen = e => {
		e.preventDefault()
		document.body.classList.add('nav-main-open')
	}

	onNavClose = e => {
		e.preventDefault()
		document.body.classList.remove('nav-main-open')
	}

	onContactOpen = e => {
		document.body.classList.add('no-scroll')
	}

	componentWillUnmount() {
		document.body.classList.remove('nav-main-open')
	}

	render() {
		return (
			<header className="site-header">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="header-wrapper">
								<div className="site-branding">
									<Link className="navbar-brand" to="/">
										<Logo />
									</Link>
								</div>
								<nav id="site-navigation" className="main-navigation">
									<ul className="primary-menu">
										<li>
											<Link to="/projects" activeClassName="active">
												projects
											</Link>
										</li>
										<li>
											<Link to="/about-me" activeClassName="active">
												about me
											</Link>
										</li>
										<li>
											<Link
												to="/say-hello"
												activeClassName="active"
												asModal
												onClick={this.onContactOpen}
											>
												say hello
											</Link>
										</li>
									</ul>
								</nav>
								<span id="nav-toggle" onClick={this.onNavOpen}>
									menu
								</span>
								<span id="nav-close" onClick={this.onNavClose}>
									back
								</span>
							</div>
						</div>
					</div>
				</div>
			</header>
		)
	}
}

export default Navbar
