import React from 'react'

import Navbar from './navbar'
import './all.sass'

class TemplateWrapper extends React.Component {
	render() {
		return (
			<div className="site-wrapper">
				<Navbar />
				<main id="main" className="site-main">
					<div className="content-wrapper">{this.props.children}</div>
				</main>
			</div>
		)
	}
}

export default TemplateWrapper
