import {
	DIV_RECT,
	DIV_REVERSE_RECT,
	IMAGE_RECT,
	IS_ANIMATION,
	ANIMATION_STATUS,
} from './types'

export const divBoundingClientRect = data => {
	return {
		type: DIV_RECT,
		payload: data,
	}
}

export const divReverseBoundingClientRect = data => {
	return {
		type: DIV_REVERSE_RECT,
		payload: data,
	}
}

export const imageBoundingClientRect = data => {
	return {
		type: IMAGE_RECT,
		payload: data,
	}
}

export const isAnimation = data => {
	return {
		type: IS_ANIMATION,
		payload: data,
	}
}

export const animationStatus = data => {
	return {
		type: ANIMATION_STATUS,
		payload: data,
	}
}
