import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-modal-routing'
import Logo from '../images/logo.svg'

class Footer extends Component {
	render() {
		const { message } = this.props
		const { link } = this.props
		return (
			<StaticQuery
				query={graphql`
					query {
						wordpressAcfOptions {
							id
							options {
								footer {
									contact {
										title
									}
								}
							}
						}
					}
				`}
				render={data => (
					<footer className="container-fluid site-footer">
						<div className="container">
							<div className="row">
								<div className="col-12 footer-wrapper">
									<div className="footer-work">
										<span>{message}</span>
										<Link className="projects-link" to={link.url}>
											{link.title}
										</Link>
									</div>
									<div className="footer-bottom">
										<Link className="navbar-brand" to="/">
											<Logo />
										</Link>
										<Link className="contact-link" to="/say-hello" asModal>
											{data.wordpressAcfOptions.options.footer.contact.title}
										</Link>
									</div>
								</div>
							</div>
						</div>
					</footer>
				)}
			/>
		)
	}
}

export default Footer
