import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectPreview from '../components/projectPreview'
import Footer from '../components/footer'

class Homepage extends React.Component {
	render() {
		const { data } = this.props
		return (
			<React.Fragment>
				<Layout>
					<SEO
						title="Homepage"
						keywords={[`portfolio`, `ux`, `ui`, `design-system`, `prototyping`]}
					/>
					<div className="container intro-heading">
						<div className="row">
							<div className="col-12 heading-wrapper">
								<h1>Example website</h1>
								<span>lore ipsum si dolore</span>
							</div>
						</div>
					</div>
					<section className="container projects-list">
						<div className="row">
							{data.wordpressPage.acf.checked_projects.map((id, index) =>
								data.allWordpressWpProject.edges.map(
									edge =>
										id.project.wordpress_id === edge.node.wordpress_id && (
											<ProjectPreview
												key={edge.node.wordpress_id}
												data={edge.node}
												index={index + 1}
											/>
										)
								)
							)}
							<div className="col-md-11 col-lg-5 more">
								<div className="more-wrapper">
									<span>work work work work work</span>
									<span className="more-title">contrast much?</span>
								</div>
							</div>
						</div>
					</section>
				</Layout>
				<Footer
					message={data.wordpressPage.acf.footer.message}
					link={data.wordpressPage.acf.footer.link}
				/>
			</React.Fragment>
		)
	}
}

export default Homepage

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		wordpressPage(
			guid: { eq: "https://examplebackend.timacumdev.com/?page_id=8" }
		) {
			acf {
				footer {
					message
					link {
						title
						url
						target
					}
				}
				checked_projects {
					project {
						wordpress_id
					}
				}
			}
		}
		...WordpressProjects
	}
`
